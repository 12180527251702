import React, {useEffect} from 'react';
import './styles/App.css';
import './styles/Header.css';

function Header() {
  return (
    <div>
      <header className="header-container">
        <nav>
          <ul className="header-list">
            <li>Home</li>
            <li>About</li>
            <li>Services</li>
            <li>Contact</li>
          </ul>
        </nav>
      </header>
      <hr className="header-line" />
    </div>
  );
}

function HeroSection() {
  return (
    <section className="hero">
      <div className="container">
        <h1>Welcome to Choc.</h1>
        <p>초콜릿에 오신걸 환영합니다.</p>
        <button>Contact Us</button>
      </div>
    </section>
  );
}

function AboutSection() {
  return (
    <section className="about">
      <div className="container">
        <h2>About Us</h2>
        <p>
          Our company is dedicated to delivering high-quality solutions to our
          clients. With a team of experts in various fields, we can tackle any
          challenge that comes our way.
        </p>
      </div>
    </section>
  );
}

function ServicesSection() {
  return (
    <section className="services">
      <div className="container">
        <h2>Our Services</h2>
        <ul>
          <li>Web Development</li>
          <li>Mobile App Development</li>
          <li>UI/UX Design</li>
          <li>SEO Services</li>
        </ul>
      </div>
    </section>
  );
}

function ContactSection() {
  return (
    <section className="contact">
      <div className="container">
        <h2>Contact Us</h2>
        <p>If you have any questions, feel free to get in touch with us.</p>
        <form>
          <input type="text" placeholder="Name" />
          <input type="email" placeholder="Email" />
          <textarea placeholder="Message"></textarea>
          <button>Send Message</button>
        </form>
      </div>
    </section>
  );
}

function Footer() {
  return (
    <footer>
      <div className="container">
        <p>&copy; {new Date().getFullYear()} Choc. All rights reserved.</p>
      </div>
    </footer>
  );
}

function App() {
  useEffect(() => {    
    document.title = 'Choc'; // 원하는 타이틀로 변경
  }, []);
  return (
    <div>
      <Header />
      <HeroSection />
      <AboutSection />
      <ServicesSection />
      <ContactSection />
      <Footer />
    </div>
  );
}

export default App;
